html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{
 background-color: #6dc1e5 !important;   
}
.MuiCheckbox-colorSecondary.Mui-checked{
  color:transparentize($color: #000000, $amount: 0) !important;  
}
.makeStyles-primary-52 {
  box-shadow:white !important;
}
.funfact-one__virus-1, .funfact-one__virus-2 {
  position: relative;
  top: calc(50% - 90.5px);
  -webkit-animation: virusMove 5s linear infinite;
          animation: virusMove 5s linear infinite;
}

.funfact-one__virus-1 {
  left: 15px;
}

.funfact-one__virus-2 {
  right: 15px;
}
.funfact-one {
  padding-top: 90px;
  padding-bottom: 100px;
  text-align: center;
}

body {
  background-color: #e5e5e5;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}



@-webkit-keyframes virusMove {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0px);
            transform: rotate(0deg) translateZ(0px);
  }
  25% {
    -webkit-transform: rotate(90deg) translateZ(20px);
            transform: rotate(90deg) translateZ(20px);
  }
  50% {
    -webkit-transform: rotate(180deg) translateZ(30px);
            transform: rotate(180deg) translateZ(30px);
  }
  75% {
    -webkit-transform: rotate(90deg) translateZ(20px);
            transform: rotate(90deg) translateZ(20px);
  }
  100% {
    -webkit-transform: rotate(0) translateZ(0px);
            transform: rotate(0) translateZ(0px);
  }
}
.FreeHS{
  text-align: center !important;
  padding-left: 0px !important;
}
.funfact-two__virus {
  position: absolute;
  right: 50%;
  bottom: 10%;
  -webkit-animation-duration: 45s;
  animation-duration: 45s;
  -webkit-perspective: 1085px;
  perspective: 1000px;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.funfact-two__virus__5 {
  position: absolute;
  left: 10%;
  top: 15em;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  -webkit-perspective: 1085px;
  perspective: 1000px;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.funfact-two__virus__7 {
  position: absolute;
  right: 10%;
  top: 20em;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-perspective: 1085px;
  perspective: 1000px;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animation01 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #384150;
}

.animation01 .line_content {
  width: 100%;
  height: 50%;
  animation: animation01_content 0.3s ease-in forwards;
}

.animation01 .line_content01 {
  transform-origin: bottom;
}

.animation01 .line_content02 {
  transform-origin: top;
}

.animation01 .line_wrapper {
  width: 100%;
  height: 100%;
}

.animation01 .line_wrapper01 {
  transform-origin: bottom;
  animation: animation01_wrapper01 0.5s ease-in-out 0.2s forwards;
}

.animation01 .line_wrapper02 {
  transform-origin: top;
  animation: animation01_wrapper02 0.5s ease-in-out 0.2s forwards;
}

.animation01 .line01 {
  width: 100%;
  height: 50%;
  background-color: #d05464;
}

.animation01 .line02 {
  width: 100%;
  height: 50%;
  background-color: #c98b4f;
}

@keyframes animation01_content {
  0% {
      transform: scale3d(1, 0, 1);
  }
  100% {
      transform: scale3d(1, 1, 1);
  }
}

@keyframes animation01_wrapper01 {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-50vw);
  }
}

@keyframes animation01_wrapper02 {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(50vw);
  }
}

/*===================
animation02
====================*/

.animation02 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.square {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 8vw;
  height: 8vw;
  margin: auto;
  background-color: #c03d5c;
  animation: square 0.2s ease-in-out 0.6s forwards;
  opacity: 0;
  z-index: 100;
}

@keyframes square {
  0% {
      transform: rotate(45deg);
      opacity: 0;
  }
  100% {
      transform: rotate(0);
      opacity: 1;
  }
}

.square_item {
  display: block;
  position: absolute;
  width: 0.5vw;
  height: 0.5vw;
  margin: auto;
  background-color: #c03d5c;
  border-radius: 50%;
  opacity: 0;
}

.square_item01 {
  top: -105%;
  right: 0;
  bottom: 0;
  left: 0;
  animation: square_item01 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item02 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 99%;
  animation: square_item02 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item03 {
  top: 105%;
  right: 0;
  bottom: 0;
  left: 0;
  animation: square_item03 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item04 {
  top: 0;
  right: 0;
  bottom: 0;
  left: -99%;
  animation: square_item04 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item05 {
  top: -105%;
  right: 0;
  bottom: 0;
  left: 99%;
  animation: square_item05 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item06 {
  top: 105%;
  right: 0;
  bottom: 0;
  left: 99%;
  animation: square_item06 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item07 {
  top: 105%;
  right: 0;
  bottom: 0;
  left: -99%;
  animation: square_item07 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.square_item08 {
  top: -105%;
  right: 0;
  bottom: 0;
  left: -99%;
  animation: square_item08 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

@keyframes square_item01 {
  0% {
      transform: translateY(0);
      opacity: 1;
  }
  100% {
      transform: translateY(-7vw);
      opacity: 1;
  }
}

@keyframes square_item02 {
  0% {
      transform: translateX(0);
      opacity: 1;
  }
  100% {
      transform: translateX(7vw);
      opacity: 1;
  }
}

@keyframes square_item03 {
  0% {
      transform: translateY(0);
      opacity: 1;
  }
  100% {
      transform: translateY(7vw);
      opacity: 1;
  }
}

@keyframes square_item03 {
  0% {
      transform: translateY(0);
      opacity: 1;
  }
  100% {
      transform: translateY(7vw);
      opacity: 1;
  }
}

@keyframes square_item04 {
  0% {
      transform: translateX(0);
      opacity: 1;
  }
  100% {
      transform: translateX(-7vw);
      opacity: 1;
  }
}

@keyframes square_item05 {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(3.5vw, -3.5vw, -3.5vw);
      opacity: 1;
  }
}

@keyframes square_item06 {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(3.5vw, 3.5vw, 3.5vw);
      opacity: 1;
  }
}

@keyframes square_item07 {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(-3.5vw, 3.5vw, 3.5vw);
      opacity: 1;
  }
}

@keyframes square_item08 {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(-3.5vw, -3.5vw, 3.5vw);
      opacity: 1;
  }
}

.circle_item01 {
  position: absolute;
  top: 150%;
  right: 0;
  bottom: 0;
  left: 90%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #6f919a;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.1s forwards;
  opacity: 0;
}

.circle_item02 {
  position: absolute;
  top: 125%;
  right: 0;
  bottom: 0;
  left: 125%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #c24a50;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.15s forwards;
  opacity: 0;
}

.circle_item03 {
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 158%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #d6a2a6;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.2s forwards;
  opacity: 0;
}

.circle_item04 {
  position: absolute;
  top: -150%;
  right: 0;
  bottom: 0;
  left: -158%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #6f919a;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.1s forwards;
  opacity: 0;
}

.circle_item05 {
  position: absolute;
  top: -125%;
  right: 0;
  bottom: 0;
  left: -227%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #c24a50;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.15s forwards;
  opacity: 0;
}

.circle_item06 {
  position: absolute;
  top: -100%;
  right: 0;
  bottom: 0;
  left: -298%;
  width: 5vw;
  height: 5vw;
  margin: auto;
  background-color: #d6a2a6;
  border-radius: 50%;
  animation: circle_item 1.0s cubic-bezier(0.19, 1, 0.22, 1) 1.2s forwards;
  opacity: 0;
}

@keyframes circle_item {
  0% {
      transform: scale3d(0, 0, 1);
      opacity: 1;
  }
  75% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
  }
  100% {
      transform: scale3d(0.5, 0.5, 1);
      opacity: 1;
  }
}

.circle_item01_1 {
  position: absolute;
  top: 150%;
  right: 0;
  bottom: 0;
  left: 106%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #6f919a;
  border-radius: 50%;
  animation: circle_twice 0.25s linear 2s forwards;
  opacity: 0;
}

.circle_item02_2 {
  position: absolute;
  top: 175%;
  right: 0;
  bottom: 0;
  left: 141%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #c24a50;
  border-radius: 50%;
  animation: circle_twice 0.245s linear 2.05s forwards;
  opacity: 0;
}

.circle_item03_3 {
  position: absolute;
  top: 200%;
  right: 0;
  bottom: 0;
  left: 175%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #d6a2a6;
  border-radius: 50%;
  animation: circle_twice 0.24s linear 2.1s forwards;
  opacity: 0;
}

.circle_item04_4 {
  position: absolute;
  top: -150%;
  right: 0;
  bottom: 0;
  left: -158%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #6f919a;
  border-radius: 50%;
  animation: circle_third 0.25s linear 2s forwards;
  opacity: 0;
}

.circle_item05_5 {
  position: absolute;
  top: -175%;
  right: 0;
  bottom: 0;
  left: -227%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #c24a50;
  border-radius: 50%;
  animation: circle_third 0.245s linear 2.05s forwards;
  opacity: 0;
}

.circle_item06_6 {
  position: absolute;
  top: -200%;
  right: 0;
  bottom: 0;
  left: -298%;
  width: 2.5vw;
  height: 2.5vw;
  margin: auto;
  background-color: #d6a2a6;
  border-radius: 50%;
  animation: circle_third 0.24s linear 2.1s forwards;
  opacity: 0;
}

@keyframes circle_twice {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  25% {
      transform: translate3d(75%, -120%, 0);
      opacity: 1;
  }
  50% {
      transform: translate3d(100%, -240%, 0);
      opacity: 1;
  }
  75% {
      transform: translate3d(75%, -360%, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(0, -480%, 0);
      opacity: 1;
  }
}

@keyframes circle_third {
  0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
  25% {
      transform: translate3d(75%, 120%, 0);
      opacity: 1;
  }
  50% {
      transform: translate3d(100%, 240%, 0);
      opacity: 1;
  }
  75% {
      transform: translate3d(75%, 360%, 0);
      opacity: 1;
  }
  100% {
      transform: translate3d(0, 480%, 0);
      opacity: 1;
  }
}

/*===================
animation03
====================*/

.animation03 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
}

.makeStyles-primaryHover-81:hover {
  color: #FFFFFF;
  box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
  background-color: #1a5790 !important;
}
.online_reports {
  background-color: red;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
  0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

.animation03 div {
  width: 10vw;
  height: 10vw;
  background-color: #ffffff;
  transform: rotate(45deg);
  animation: bg 0.5s linear 2.5s forwards;
  opacity: 0;
  z-index: 99;
}

@keyframes bg {
  0% {
      transform: scale3d(0, 0, 0) rotate(45deg);
      opacity: 1;
  }
  75% {
      transform: scale3d(1, 1, 1) rotate(45deg);
      opacity: 1;
  }
  100% {
      transform: scale3d(1.5, 1.5, 1) rotate(45deg);
      opacity: 1;
  }
}

/*===================
animation04
====================*/
section#counter-stats {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.stats {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.stats .fa {
  color: #008080;
  font-size: 60px;
}


.stepper {
  width: 30px;
  height: 30px;
  background: '#1a5790';
  cursor: pointer;
  width: 7em;
  height: 130px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: '0.5vw';
  position: relative;
  box-shadow: -1px 57px 80px -17px rgba(0, 8, 255, 0.25);
  z-index: 2;

  .span_data {
      user-select: none;
      top: 28px;
      left: 50%;
      transform: translateY(0) translateX(-50%);
      &.active {
          transform: translateY(0) translateX(-50%);
      }

      &.hide {
          opacity: 0;
      }
  }
}
.animation04 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 9998;
}

.name_plate {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40em;
  height: 15em;
  margin: auto;
  background-color: #3d3c3e;
  transform-origin: center;
  animation: name_plate 1s cubic-bezier(0.165, 0.84, 0.44, 1) 3.1s forwards;
  opacity: 0;
}

@keyframes name_plate {
  0% {
      transform: scale3d(0, 1, 1);
      opacity: 1;
  }
  100% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
  }
}

.name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  animation: name 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 4.1s forwards;
  opacity: 0;
}

@keyframes name {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@-webkit-keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes virusMove {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0px);
            transform: rotate(0deg) translateZ(0px);
  }
  25% {
    -webkit-transform: rotate(90deg) translateZ(20px);
            transform: rotate(90deg) translateZ(20px);
  }
  50% {
    -webkit-transform: rotate(180deg) translateZ(30px);
            transform: rotate(180deg) translateZ(30px);
  }
  75% {
    -webkit-transform: rotate(90deg) translateZ(20px);
            transform: rotate(90deg) translateZ(20px);
  }
  100% {
    -webkit-transform: rotate(0) translateZ(0px);
            transform: rotate(0) translateZ(0px);
  }
}


// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}
img {
  vertical-align: middle;
  border-style: none;
}
form {
  margin-bottom: 1.125rem;
}
